<template>
    <Page title="Company AP Delete" icon="mdi-profile">
        <v-container>
            <smart-form
                v-if="formModel"
                v-model="formModel"
                :schema="formSchema"
                form-code="company-apdelete"
            />
            <toolbar
                :left-items="[{ id: 'delete', label: 'AP', onClick: deleteAP }]"
                :right-items="[
                    { label: 'Reload from QB', onClick: reloadBillsAndVendorCredits },
                    { id: 'done', onClick: toListView },
                ]"
            />
        </v-container>
    </Page>
</template>
<script>
import api from '@/api';

const formSchema = {
    companyId: {
        component: 'TextField',
        autocomplete: {
            type: 'company',
        },
        label: 'Company',
        cols: 6,
        clearable: true,
    },
    customerId: {
        component: 'TextField',
        autocomplete: {
            type: 'customer',
        },
        label: 'Customer',
        cols: 6,
        clearable: true,
    },
    // doesnt wrap on 12 columns
    // blank1:{
    //     component: 'BlankField',
    //     cols: 6
    // },
    yearMonth: {
        component: 'dateField',
        label: 'Delete Month',
        month: true,
        cols: 6,
        today: true,
    },
};

export default {
    name: 'CompanyDetail',
    data() {
        return {
            formModel: {
                companyId: '',
                yearMonth: '2021-11',
            },
            formSchema,
        };
    },
    methods: {
        logForm() {
            console.log(JSON.stringify(this.formModel, null, 2));
        },
        async deleteAP() {
            console.log('deleteAP', this.formModel);
            const result1 = await api.post(this.$store, 'bill/deleteCompanyAP', this.formModel);
            console.log({ result1 });
            // this.formModel = result.data;
        },
        async reloadFromQB(entity) {
            console.log('what?? ', entity);

            try {
                const loadWhatResult = await api.post(this.$store, 'datasync/reloaddata', {
                    from: 'qb',
                    entity,
                    companyId: this.formModel.companyId,
                });
                console.log('result', loadWhatResult);
            } catch (e) {
                console.log('error reload', e.message);
            }

            return entity;
        },
        async reloadBillsAndVendorCredits() {
            if (this.formModel.companyId) {
                await this.reloadFromQB('Bill');
                await this.reloadFromQB('VendorCredit');
            } else {
                console.log(`Choose a company.`);
            }
        },

        fakeDelete(val) {
            console.log('fakedelete', val);
        },
        toListView() {
            this.$router.push('/auth/admin/company');
        },
    },
    async mounted() {
        //await this.getFormData();
    },
};
</script>
<!--
       <toolbar
            :left-buttons="[
                { text: 'Click Something', name: 'something', onClick: logForm, className: 'primary' },
                {
                    text: 'Delete Something',
                    name: 'deletesomething',
                    onClick: fakeDelete,
                    className: 'warning',
                    confirm: 'Are you sure you want to delete?',
                },
                {
                    text: 'Delete Another',
                    name: 'another',
                    onClick: fakeDelete,
                    className: 'error',
                    confirm: 'Are you sure you want to delete another?',
                },
            ]"
            :right-buttons="[
                { text: 'Done', name: 'done', onClick: logForm, className: 'primary' },

            ]"
        />
-->
